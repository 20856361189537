import React from "react";
import {ToastContainer} from 'react-toastify';
import * as Jivosite from 'react-jivosite'


import 'react-toastify/dist/ReactToastify.css';
import './../static/css/bootstrap.css'
import './../static/css/style.css'

const Wrapper = ({className, children}) => (
    <div className={`main ${className}`}>{children}</div>
);

export const Base = ({children}) => {
    return <React.Fragment>
        <Wrapper className="">
            {children}
        </Wrapper>
        <Jivosite.Widget id="uBb4LaEhyc"/>
        <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
    </React.Fragment>
};
