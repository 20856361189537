import {useContext, useEffect, useRef, useState} from 'react'

import {AutoTextSize} from 'auto-text-size'
import {Loader} from "../Loader";
import {AppContext} from "../../store/context";

import A1 from "../../static/assets/domznak/figures/A1.jpg"
import A2 from "../../static/assets/domznak/figures/A2.jpg"
import A3 from "../../static/assets/domznak/figures/A3.jpg"
import A4 from "../../static/assets/domznak/figures/A4.jpg"

import C1 from "../../static/assets/domznak/figures/C1.jpg"
import C2 from "../../static/assets/domznak/figures/C2.jpg"
import C3 from "../../static/assets/domznak/figures/C3.jpg"
import C4 from "../../static/assets/domznak/figures/C4.jpg"

import B1 from "../../static/assets/domznak/figures/B1.png"
import B2 from "../../static/assets/domznak/figures/B2.png"
import B3 from "../../static/assets/domznak/figures/B3.png"
import B4 from "../../static/assets/domznak/figures/B4.png"
import B5 from "../../static/assets/domznak/figures/B5.png"
import B6 from "../../static/assets/domznak/figures/B6.png"
import B7 from "../../static/assets/domznak/figures/B7.png"
import B8 from "../../static/assets/domznak/figures/B8.png"
import B9 from "../../static/assets/domznak/figures/B9.png"
import B10 from "../../static/assets/domznak/figures/B10.png"
import B11 from "../../static/assets/domznak/figures/B11.png"
import B12 from "../../static/assets/domznak/figures/B12.png"
import B13 from "../../static/assets/domznak/figures/B13.png"
import B14 from "../../static/assets/domznak/figures/B14.png"
import B15 from "../../static/assets/domznak/figures/B15.png"

import SB1 from "../../static/assets/domznak/samples/B1.jpg"
import SB2 from "../../static/assets/domznak/samples/B2.jpg"
import SB3 from "../../static/assets/domznak/samples/B3.jpg"
import SB4 from "../../static/assets/domznak/samples/B4.jpg"
import SB5 from "../../static/assets/domznak/samples/B5.jpg"
import SB6 from "../../static/assets/domznak/samples/B6.jpg"
import SB7 from "../../static/assets/domznak/samples/B7.jpg"
import SB8 from "../../static/assets/domznak/samples/B8.jpg"
import SB9 from "../../static/assets/domznak/samples/B9.jpg"
import SB10 from "../../static/assets/domznak/samples/B10.jpg"
import SB11 from "../../static/assets/domznak/samples/B11.jpg"
import SB12 from "../../static/assets/domznak/samples/B12.jpg"
import SB13 from "../../static/assets/domznak/samples/B13.jpg"
import SB14 from "../../static/assets/domznak/samples/B14.jpg"
import SB15 from "../../static/assets/domznak/samples/B15.jpg"

import SA1 from "../../static/assets/domznak/samples/A1.jpg"
import SA2 from "../../static/assets/domznak/samples/A2.jpg"
import SA3 from "../../static/assets/domznak/samples/A3.jpg"
import SA4 from "../../static/assets/domznak/samples/A4.jpg"

import SC1 from "../../static/assets/domznak/samples/C1.jpg"
import SC2 from "../../static/assets/domznak/samples/C2.jpg"
import SC3 from "../../static/assets/domznak/samples/C3.jpg"
import SC4 from "../../static/assets/domznak/samples/C4.jpg"

const types = [
    {
        name: "Прямоугольные",
        figures: [
            {
                oneliner: true,
                curved: false,
                sizes: '100x25',
                withOptions: true, maxFontSize: {
                    street: 70,
                    house: 120
                },
                article: "B1", figure: B1, sample: SB1,
                materials: [
                    {type: "pvh", material: "ПВХ",},
                    {type: "alk", material: "Алюкобонд",}
                ]
            },
            {
                oneliner: true,
                curved: false,
                sizes: '60x25',
                withOptions: true, maxFontSize: {
                    street: 56,
                    house: 120
                },
                article: "B2", figure: B2, sample: SB2,
                materials: [
                    {type: "pvh", material: "ПВХ",},
                    {type: "alk", material: "Алюкобонд",}
                ]
            },
            {
                oneliner: false,
                curved: false,
                sizes: '60x45',
                withOptions: true, maxFontSize: {
                    street: 56,
                    house: 120
                },
                article: "B7", figure: B7, sample: SB7,
                materials: [
                    {type: "pvh", material: "ПВХ",},
                    {type: "alk", material: "Алюкобонд",}
                ]
            },
            {
                oneliner: false,
                curved: false,
                sizes: '100x25',
                withOptions: true, maxFontSize: {
                    street: 56,
                    house: 120
                },
                article: "B8", figure: B8, sample: SB8,
                materials: [
                    {type: "pvh", material: "ПВХ",},
                    {type: "alk", material: "Алюкобонд",}
                ]
            },
            {
                oneliner: false,
                curved: false,
                sizes: '60x15',
                withOptions: true, maxFontSize: {
                    street: 46,
                    house: 60
                },
                article: "B9", figure: B9, sample: SB9,
                materials: [
                    {type: "pvh", material: "ПВХ",},
                    {type: "alk", material: "Алюкобонд",}
                ]
            },
        ],
    },
    {
        name: "Фигурные",
        figures: [
            {
                oneliner: false,
                curved: false,
                sizes: '100x50',
                withOptions: true, maxFontSize: {
                    street: 56,
                    house: 120
                },
                article: "B3", figure: B3, sample: SB3,
                materials: [
                    {type: "pvh", material: "ПВХ",},
                    {type: "alk", material: "Алюкобонд",}
                ]
            },
            {
                oneliner: false,
                curved: false,
                sizes: '60x30',
                withOptions: true, maxFontSize: {
                    street: 36,
                    house: 76
                },
                article: "B4", figure: B4, sample: SB4,
                materials: [
                    {type: "pvh", material: "ПВХ",},
                    {type: "alk", material: "Алюкобонд",}
                ]
            },
            {
                oneliner: false,
                curved: false,
                sizes: '100x35',
                withOptions: true, maxFontSize: {
                    street: 72,
                    house: 90
                },
                article: "B10", figure: B10, sample: SB10,
                materials: [
                    {type: "pvh", material: "ПВХ",},
                    {type: "alk", material: "Алюкобонд",}
                ]
            },
            {
                oneliner: false,
                curved: false,
                sizes: '60x21',
                withOptions: true, maxFontSize: {
                    street: 44,
                    house: 52
                },
                article: "B11", figure: B11, sample: SB11,
                materials: [
                    {type: "pvh", material: "ПВХ",},
                    {type: "alk", material: "Алюкобонд",}
                ]
            },
            {
                oneliner: false,
                curved: false,
                sizes: '100x50',
                withOptions: true, maxFontSize: {
                    street: 68,
                    house: 144
                },
                article: "B14", figure: B14, sample: SB14,
                materials: [
                    {type: "pvh", material: "ПВХ",},
                    {type: "alk", material: "Алюкобонд",}
                ]
            },
            {
                oneliner: false,
                curved: false,
                sizes: '60x30',
                withOptions: true, maxFontSize: {
                    street: 45,
                    house: 100
                },
                article: "B15", figure: B15, sample: SB15,
                materials: [
                    {type: "pvh", material: "ПВХ",},
                    {type: "alk", material: "Алюкобонд",}
                ]
            },
        ],
    },
    {
        name: "Винтажные",
        figures: [
            {
                oneliner: false,
                curved: false,
                sizes: '100x50',
                withOptions: true, maxFontSize: {
                    street: 64,
                    house: 100
                },
                article: "B5", figure: B5, sample: SB5,
                materials: [
                    {type: "pvh", material: "ПВХ",},
                    {type: "alk", material: "Алюкобонд",}
                ]
            },
            {
                oneliner: false,
                curved: false,
                sizes: '60x30',
                withOptions: true, maxFontSize: {
                    street: 38,
                    house: 60
                },
                article: "B6", figure: B6, sample: SB6,
                materials: [
                    {type: "pvh", material: "ПВХ",},
                    {type: "alk", material: "Алюкобонд",}
                ]
            },
            {
                oneliner: false,
                curved: true,
                sizes: '100x43',
                withOptions: true, maxFontSize: {
                    street: 50,
                    house: 100
                },
                article: "B12", figure: B12, sample: SB12,
                materials: [
                    {type: "pvh", material: "ПВХ",},
                    {type: "alk", material: "Алюкобонд",}
                ]
            },
            {
                oneliner: false,
                curved: true,
                sizes: '100x26',
                withOptions: true, maxFontSize: {
                    street: 38,
                    house: 60
                },
                article: "B13", figure: B13, sample: SB13,
                materials: [
                    {type: "pvh", material: "ПВХ",},
                    {type: "alk", material: "Алюкобонд",}
                ]
            },
        ],
    },
    {
        name: "Информационные", figures: [
            {
                oneliner: false,
                curved: false,
                withOptions: false,
                sizes: '40x53',
                maxFontSize: {
                    street: 64,
                    house: 100
                },
                article: "A1", figure: A1, sample: SA1,
                materials: [
                    {type: "pvh", material: "ПВХ",},
                    {type: "alk", material: "Алюкобонд",}
                ]
            },
            {
                oneliner: false,
                curved: false,
                withOptions: false,
                sizes: '50x30',
                maxFontSize: {
                    street: 64,
                    house: 100
                },
                article: "A2", figure: A2, sample: SA2,
                materials: [
                    {type: "pvh", material: "ПВХ",},
                    {type: "alk", material: "Алюкобонд",}
                ]
            },
            {
                oneliner: false,
                curved: false,
                withOptions: false,
                sizes: '40x30',
                maxFontSize: {
                    street: 64,
                    house: 100
                },
                article: "A3", figure: A3, sample: SA3,
                materials: [
                    {type: "pvh", material: "ПВХ",},
                    {type: "alk", material: "Алюкобонд",}
                ]
            },
            {
                oneliner: false,
                curved: false,
                withOptions: false,
                sizes: '60x30',
                maxFontSize: {
                    street: 64,
                    house: 100
                },
                article: "A4", figure: A4, sample: SA4,
                materials: [
                    {type: "pvh", material: "ПВХ",},
                    {type: "alk", material: "Алюкобонд",}
                ]
            },

            {
                oneliner: false,
                curved: false,
                withOptions: false,
                sizes: '35x43',
                maxFontSize: {
                    street: 64,
                    house: 100
                },
                article: "C1", figure: C1, sample: SC1,
                materials: [
                    {type: "pvh", material: "ПВХ",},
                    {type: "alk", material: "Алюкобонд",}
                ]
            },
            {
                oneliner: false,
                curved: false,
                withOptions: false,
                sizes: '50x35',
                maxFontSize: {
                    street: 64,
                    house: 100
                },
                article: "C2", figure: C2, sample: SC2,
                materials: [
                    {type: "pvh", material: "ПВХ",},
                    {type: "alk", material: "Алюкобонд",}
                ]
            },
            {
                oneliner: false,
                curved: false,
                withOptions: false,
                sizes: '40x25',
                maxFontSize: {
                    street: 64,
                    house: 100
                },
                article: "C3", figure: C3, sample: SC3,
                materials: [
                    {type: "pvh", material: "ПВХ",},
                    {type: "alk", material: "Алюкобонд",}
                ]
            },
            {
                oneliner: false,
                curved: false,
                withOptions: false,
                sizes: '60x40',
                maxFontSize: {
                    street: 64,
                    house: 100
                },
                article: "C4", figure: C4, sample: SC4,
                materials: [
                    {type: "pvh", material: "ПВХ",},
                    {type: "alk", material: "Алюкобонд",}
                ]
            },
        ]
    }
];
const colors = [
    {name: "graphite", label: "Графит"},
    {name: "brown", label: "Коричневый"},
    {name: "black", label: "Черный"},
    {name: "red", label: "Красный"},
    {name: "green", label: "Зеленый"},
    {name: "peach", label: "Персиковый"},
    {name: "blue", label: "Синий"},
    {name: "deep-blue", label: "Темно-синий"},
    {name: "naviblue", label: "Глубокий синий"},
];

export const Calculator = () => {
    const {context} = useContext(AppContext);

    const [type, setType] = useState(types[0]);
    const [selected, setSelected] = useState(types[0].figures[0]);
    const [color, setColor] = useState(colors[0]);
    const [street, setStreet] = useState('3-я Шоссейная');
    const [house, setHouse] = useState('33');
    const [material, setMaterial] = useState('pvh');

    const [prices, setPrices] = useState(null);

    useEffect(() => {
        context.processor.screen.getScreen('domznak').then(res => {
            setPrices(res);
        })
    }, []);

    const checkout = () => {
        context.domznak.order.dispatch({
            type: 'prepareForm', payload: {
                material: material,
                address: street,
                house: house,
                color: color.label,
                article: selected.article
            }
        })
    };

    return prices ? <div className="calculator">
        <div className="calculator-selector">
            <div className="calculator-types">
                {types.map(el => {
                    return <div className={`calculator-type ${el.name === type.name ? 'active' : ''}`} onClick={() => {
                        setType(el)
                    }}>{el.name}</div>
                })}
            </div>
            <div className="calculator-variants">
                {type.figures.map(el => {
                    return <div className={`calculator-variant ${selected.article == el.article ? 'active' : ''}`}
                                onClick={() => {
                                    setSelected(el)
                                }}>
                        <img src={el.sample} alt=""/>
                    </div>
                })}
            </div>
        </div>
        <div className="calculator-preview">
            <div className={`calculator-image ${color.name} ${selected.article}`}>
                <div className="calculator-size">{selected.sizes} см</div>
                {selected.withOptions ?
                    selected.oneliner ?
                    <div className="calculator-preview-street">
                        <AutoTextSize maxFontSizePx={selected.maxFontSize.street}>{street}, {house}
                    </AutoTextSize>
                    </div> : <>
                    <div className="calculator-preview-street">
                    {selected.curved
                        ? <Curved small={selected.article == "B13"}
                                  fontSize={selected.maxFontSize.street}
                                  text={street}/>
                        : <AutoTextSize maxFontSizePx={selected.maxFontSize.street}>{street}</AutoTextSize>
                    }
                    </div>
                    <div className="calculator-preview-house">
                    <AutoTextSize maxFontSizePx={selected.maxFontSize.house}>{house}</AutoTextSize>
                    </div>
                    </> : null}
                <img src={selected ? selected.figure : null} alt=""/>
            </div>
            <div className="calculator-settings">
                {selected.withOptions ? <>
                        <div className="calcualator-settings-row">
                            <div className="form-group">
                                <label>Цвет</label>
                                <div className="plate-colors">
                                    {colors.map(el => {
                                        return <div className={`plate-color plate-color--${el.name}`} onClick={() => {
                                            setColor(el)
                                        }}/>
                                    })}
                                </div>
                            </div>

                            <div className="form-group">
                                <label>Материал</label>
                                <div className="plate-materials">
                                    {selected.materials.map(el => {
                                        return <div className={`plate-material ${el.type === material ? 'active' : ''}`}
                                                    onClick={() => {
                                                        setMaterial(el.type)
                                                    }}>{el.material}</div>
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Улица</label>
                            <input type={"text"}
                                   value={street}
                                   onChange={(e) => {
                                       setStreet(e.currentTarget.value)
                                   }} className={'form-control'} placeholder={'Улица'}/>
                        </div>
                        <div className="form-group">
                            <label>Дом</label>
                            <input type={"text"}
                                   value={house}
                                   onChange={(e) => {
                                       setHouse(e.currentTarget.value)
                                   }}
                                   className={'form-control'} placeholder={'Дом'}/>
                        </div>
                    </> : null}
                <span className={'text-muted'} style={{fontSize: '12px'}}>Изображения на сайте носят ознакомительный характер и внешний вид конечнего изделия может отличатся от изображения на сайте. Пожалуйста, уточняйте дополнительную информацию у мененеджера</span>
                <br/>
                <br/>
                <div className="calcualator-settings-row">
                    <div className="form-group calculator-price">
                        <label>Цена:</label>
                        <span>{prices[selected.article][material] || '-'} руб.</span>
                    </div>
                    <div className="calculator-checkout">
                        <div className="btn btn-primary btn-xl text-uppercase" onClick={() => {
                            checkout()
                        }}>Оформить заказ
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> : <Loader/>
};

const Curved = ({text, fontSize, small}) => {
    let ref = useRef();

    useEffect(() => {
        if (ref != null && ref.current != null) {
            let canvas = ref.current;
            let context = canvas.getContext("2d");

            // Use the identity matrix while clearing the canvas
            context.setTransform(1, 0, 0, 1, 0, 0);
            context.clearRect(0, 0, canvas.width, canvas.height);

            context.font = `bold ${fontSize}px serif`;
            context.fontWeight = "bold";
            context.fillStyle = "white";
            context.textAlign = "center";

            let string = text.toUpperCase();
            let angle, radius;

            if (small) {
                angle = Math.PI * 0.24; // in radians
                radius = 420;
                context.translate(300, radius + 120);
            } else {
                angle = Math.PI * 0.24; // in radians
                radius = 720;
                context.translate(280, radius + 120);
            }

            context.rotate(-1 * angle / 2);

            for (let i = 0; i < string.length; i++) {
                context.rotate(angle / string.length);
                context.save();
                context.translate(0, -1 * radius);
                context.fillText(string[i], 0, 0);
                context.restore();
            }
        }
    }, [text, small]);

    return <canvas style={{transform: "rotate(-1deg)"}} ref={ref} id="canv" width="600" height="250"/>
};