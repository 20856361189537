import {useContext, useEffect, useState} from 'react'
import {Col, Form, FormFeedback, Input, Modal, ModalBody, ModalHeader, Row} from 'reactstrap'
import {AppContext} from "../../store/context";

export const FeedbackForm = () => {
    const {context} = useContext(AppContext);

    const [phone, setPhone] = useState(null);
    const [name, setName] = useState(null);
    const [comment, setComment] = useState(null);

    const [progress, setProgress] = useState(false);

    const [errors, setErrors] = useState({
        name: false,
        phone: false,
    });

    const submit = () => {
        if (progress) {
            return;
        }

        const err = {...errors}

        err.phone = !phone || phone == ""
        err.name = !name || name == ""

        if (err.phone || err.name) {
            setErrors(err)
            return
        } else {
            setErrors({
                name: false,
                phone: false,
            })
        }

        const data = {
            phone: phone,
            name: name,
            comment: comment,
        };

        setProgress(true)

        context.processor.domznak.feedback(data).then(res => {
            context.toast.success('Ваше сообщение успешно отправлено. В ближайшее время с вами свяжется менеджер для уточнения деталей')
        }).catch(e => {
            context.toast.error(e.message)
        }).finally(() => {
            setProgress(false)
        })
    };

    return <Form className="contact-form">
        <Row>
            <Col md={12}>
                <div className={'form-group form-group-textarea'}>
                    <Input value={comment} placeholder={'Комментарий'} type={"textarea"} onChange={(e) => {
                        setComment(e.currentTarget.value)
                    }}/>
                </div>
            </Col>
        </Row>
        <Row className={"mt-3 align-items-stretch"}>
            <Col md={4}>
                <div className="form-group">
                    <Input value={name} invalid={errors.name} placeholder={'Ваше имя'} onChange={(e) => {
                        setName(e.currentTarget.value)
                    }}/>
                    <FormFeedback>
                        Пожалуйста, укажите имя
                    </FormFeedback>
                </div>

            </Col>
            <Col md={4}>
                <div className="form-group">
                    <Input value={phone} invalid={errors.phone} placeholder={'Контактный номер'} required
                           onChange={(e) => {
                               setPhone(e.currentTarget.value)
                           }}/>
                    <FormFeedback>
                        Пожалуйста, укажите контактный номер телефона
                    </FormFeedback>
                </div>
            </Col>
            <Col md={4}>
                <div className="btn btn-primary btn-xl text-uppercase" style={{width: "100%"}} onClick={() => {
                    submit()
                }}>{progress ? 'Отправляем..' : 'Отправить'}
                </div>
            </Col>
        </Row>
    </Form>
};


export const OrderForm = () => {
    const {context} = useContext(AppContext);


    const [progress, setProgress] = useState(false);

    const [phone, setPhone] = useState(null);
    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);
    const [comment, setComment] = useState(null);
    const [errors, setErrors] = useState({
        name: false,
        phone: false,
        email: false
    });

    useEffect(() => {
        setPhone(null);
        setName(null);
        setComment(null);
        setErrors({
            name: false,
            phone: false,
            email: false
        })
    }, [context.domznak.order.state.open]);


    const submit = () => {
        if (progress) {
            return
        }

        const err = {...errors}

        err.phone = !phone || phone == ""
        err.name = !name || name == ""
        err.email = !email || email == ""

        if (err.phone || err.name || err.email) {
            setErrors(err);
            return
        } else {
            setErrors({
                name: false,
                phone: false,
                email: false
            })
        }

        const data = {
            phone: phone,
            name: name,
            email: email,
            comment: comment,
            order: {
                color: context.domznak.order.state.color,
                material: context.domznak.order.state.material,
                article: context.domznak.order.state.article,
                address: context.domznak.order.state.address,
                house: context.domznak.order.state.house,
            }
        };
        setProgress(true)

        context.processor.domznak.feedback(data).then(res => {
            context.domznak.order.dispatch({type: 'toggleModal'});
            context.toast.success('Ваш заказ успешно сохранен. В ближайшее время с вами свяжется менеджер для уточнения деталей')
        }).catch(e => {
            context.toast.error(e.message)
        }).finally(() => {
            setProgress(false)
        })
    };

    return <Modal isOpen={context.domznak.order.state.open} toggle={() => {
        context.domznak.order.dispatch({type: 'toggleModal'});
    }}>
        <ModalHeader toggle={() => {
            context.domznak.order.dispatch({type: 'toggleModal'});
        }}>
            <h3>Оформление заказ</h3>
        </ModalHeader>
        <ModalBody>
            <div className="details">
                <div>
                    <strong>Цвет:</strong> {context.domznak.order.state.color}
                </div>
                <div>
                    <strong>Артикул:</strong> {context.domznak.order.state.article}<br/>
                </div>
                <div>
                    <strong>Материал:</strong> {context.domznak.order.state.material === 'alk' ? 'Алюкобонд' : 'ПВХ'}<br/>
                </div>
            </div>
            <Form className="contact-form">
                <Row className={"align-items-stretch"}>
                    <Col md={4}>
                        <div className="form-group">
                            <Input value={name} invalid={errors.name} placeholder={'Ваше имя*'} onChange={(e) => {
                                setName(e.currentTarget.value)
                            }}/>
                            <FormFeedback>
                                Пожалуйста, укажите имя
                            </FormFeedback>
                        </div>

                    </Col>
                    <Col md={4}>
                        <div className="form-group">
                            <Input value={phone} invalid={errors.phone} placeholder={'Контактный номер*'} required
                                   onChange={(e) => {
                                       setPhone(e.currentTarget.value)
                                   }}/>
                            <FormFeedback>
                                Пожалуйста, укажите контактный номер телефона
                            </FormFeedback>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="form-group">
                            <Input value={email} invalid={errors.email} placeholder={'E-mail*'} required
                                   onChange={(e) => {
                                       setEmail(e.currentTarget.value)
                                   }}/>
                            <FormFeedback>
                                Пожалуйста, укажите e-mail
                            </FormFeedback>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div className={'form-group form-group-textarea'}>
                            <Input value={comment} placeholder={'Комментарий'} type={"textarea"} onChange={(e) => {
                                setComment(e.currentTarget.value)
                            }}/>
                        </div>
                    </Col>
                </Row>
                <Row className={"mt-3"}>
                    <Col md={12} className={'d-flex justify-content-center'}>
                        <div className="btn btn-primary btn-xl text-uppercase" onClick={() => {
                            submit()
                        }}>{progress ? 'Оформляем..' : 'Оформить заказ'}
                        </div>
                    </Col>
                </Row>
            </Form>
        </ModalBody>
    </Modal>
}