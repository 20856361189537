import React from 'react'

import {Calculator} from '../../components/domznak/Calculator'
import {Footer} from "../../components/base/Footer";
import {FeedbackForm} from "../../components/domznak/Form";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Zoom} from 'swiper'
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/zoom';

import banner from '../../static/assets/domznak/images/banner.jpg'

import logo from '../../static/assets/domznak/logo.svg'


import p1 from '../../static/assets/domznak/images/1.jpg'
import p2 from '../../static/assets/domznak/images/2.jpg'
import p3 from '../../static/assets/domznak/images/3.jpg'
import p4 from '../../static/assets/domznak/images/4.jpg'
import p5 from '../../static/assets/domznak/images/5.jpg'

import p6 from '../../static/assets/domznak/images/R0.jpeg'
import p7 from '../../static/assets/domznak/images/R1.jpeg'
import p8 from '../../static/assets/domznak/images/R2.jpeg'
import p10 from '../../static/assets/domznak/images/R4.jpeg'
import p11 from '../../static/assets/domznak/images/R5.jpeg'
import p13 from '../../static/assets/domznak/images/R7.jpeg'
import p14 from '../../static/assets/domznak/images/R8.jpeg'

export class _Main extends React.Component {

    constructor() {
        super()
    };

    render() {
        return <>
            <nav className="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav">
                <div className="container">
                    <a className="navbar-brand js-scroll-trigger" href="/">
                        <img src={logo} alt=""/></a>
                    <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
                            data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false"
                            aria-label="Toggle navigation">
                        <i className="fas fa-bars"/>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarResponsive">
                        <ul className="navbar-nav text-uppercase ml-auto">
                            <li className="nav-item">
                                <a className="nav-link js-scroll-trigger" href="#about">О табличках</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link  js-scroll-trigger" href="#calculator">
                                    Калькулятор
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link js-scroll-trigger" href="#plates">Наши работы</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link js-scroll-trigger" href="#contact">Контакты</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div className="masthead">
                <Swiper slidesPerView={1}>
                    <SwiperSlide>
                        <div className="head-slide"
                             style={{backgroundImage: `url(${banner})`}}/>
                    </SwiperSlide>
                </Swiper>
            </div>
            <section className="page-section" id="about">
                <div className="container">
                    <div className="text-center">
                        <h2 className="mb-3 section-heading text-uppercase">Адресные таблички от Универсада</h2>
                    </div>
                    <div className="row text-center">
                        <div className="col-md-12 text-justify">
                            <div className="col-md-12 mt-5 text-justify">
                                Хотите купить таблички на дом традиционной формы? Или винтажной?
                                В нашем ассортименте представлены различные варианты адресных табличек.
                                Строгость и элегантность дизайна, высококачественное покрытие, устойчивое к воздействию
                                влаги
                                и солнечных лучей, широкая цветовая гамма, выбор материала.
                            </div>
                            <div className="col-md-12 mt-5 text-justify">
                                Таблички изготавливаются из таких материалов, как ПВХ и Алюкобонд.
                                Поливинилхлорид (ПВХ) - бесцветная, прозрачная пластмасса, термопластичный полимер
                                винилхлорида. Отличается химической стойкостью к щелочам, минеральным маслам, многим
                                кислотам и растворителям. Одни из самых главных важнейших качеств: лёгкость и прочность.
                                Подвержено малой морозостойкостью (−15 °C), нагревостойкость: +66 °C.
                            </div>
                            <div className="col-md-12 mt-5 text-justify">
                                Производство аллюкобонда представляет собой два алюминиевых листа толщиной до 0.5мм с
                                прослойкой из пластика. Элементы склеиваются и запрессовываются между собой. Чтобы
                                панели не
                                потеряли декоративность при транспортировке лицевая сторона материала защищается пленкой
                                ПВХ. Мы используем покрытие от производителя, которое зарекомендовало себя на рынке.
                            </div>
                            <div className="col-md-12 mt-5 text-justify">
                                Позвоните нам или напишите – мы предложим вам готовые решения для вашего дома!
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="page-section" id="calculator">
                <div className="container">
                    <div className="text-center">
                        <h2 className="section-heading text-uppercase">Калькулятор</h2>
                    </div>
                    <Calculator/>
                </div>
            </section>
            <section className="page-section bg-light">
                <div className="container-fluid">
                    <div className="text-center">
                        <h2 className="section-heading text-uppercase">Наши работы</h2>
                    </div>
                    <div className="row mt-5">
                        <div className="col-md-12">
                            <Swiper
                                modules={[Zoom]}
                                id={'plates'}
                                spaceBetween={50}
                                slidesPerView={1}
                                breakpoints={{
                                    // when window width is >= 640px
                                    640: {
                                        slidesPerView: 1,
                                    },
                                    // when window width is >= 768px
                                    768: {
                                        slidesPerView: 2,
                                    },
                                }}
                            >
                                <SwiperSlide>
                                    <div className="image" style={{backgroundImage: `url(${p1})`}}/>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="image" style={{backgroundImage: `url(${p2})`}}/>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="image" style={{backgroundImage: `url(${p3})`}}/>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="image" style={{backgroundImage: `url(${p4})`}}/>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="image" style={{backgroundImage: `url(${p5})`}}/>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="image" style={{backgroundImage: `url(${p6})`}}/>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="image" style={{backgroundImage: `url(${p7})`}}/>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="image" style={{backgroundImage: `url(${p8})`}}/>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="image" style={{backgroundImage: `url(${p10})`}}/>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="image" style={{backgroundImage: `url(${p11})`}}/>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="image" style={{backgroundImage: `url(${p13})`}}/>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="image" style={{backgroundImage: `url(${p14})`}}/>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
            <section className="page-section" id="contact">
                <div className="container">
                    <div className="text-center">
                        <h2 className="section-heading text-uppercase">Оставить заявку</h2>
                    </div>
                    <div>
                        <FeedbackForm/>
                    </div>
                </div>
            </section>
            <Footer/>
        </>

    }
}