import * as React from "react";

export interface WidgetProps {
    id: string;
}

const mount = (id: string) => {
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.async = true;
    script.src = `//code.jivosite.com/script/widget/${id}}`;

    const neighbour = document.getElementsByTagName('script')[ 0 ];
    neighbour.parentNode.insertBefore(script, neighbour);

    return () => {
        neighbour.parentNode.removeChild(script);
    };
};

export const Widget: React.FunctionComponent<WidgetProps> = React.memo(({ id }) => {
    React.useEffect(() => {
        if (document.readyState === "complete") {
            return mount(id);
        }

        let unmount: (() => void) | undefined;
        const onLoad = () => {
            unmount = mount(id);
        };
        window.addEventListener("load", onLoad);

        return () => {
            document.removeEventListener("load", onLoad);
            if (unmount) {
                unmount();
            }
        };
    });

    return null;
});

Widget.displayName = 'JivoSiteWidget';
