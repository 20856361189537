import {Base} from "./base.api";
import {API_HOST} from "../const";

const BASE_URL = `${API_HOST}/screen`;

export class Screen extends Base {
    getScreen(screen) {
        return this.fetchJSON(`${BASE_URL}/${screen}`).then(response => {
            return response.data
        })
    }
}