import {useContext} from 'react'
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {_Main as DomZnakMain} from "../views/DomZnak/Main"
import {Main as SadServiceMain} from "../views/SadService/Main"

import {AppContext} from "../store/context";
import {LAYOUT_DOMZNAK, LAYOUT_SADSERVICE} from "../const";
import {LoaderFullsize} from "../components/Loader"
import {DomZnak} from "../layouts/DomZnak";
import {SadService} from "../layouts/SadService";

const SiteSadService = [
    {
        path: "/",
        layout: SadService,
        component: SadServiceMain
    },
];

const SiteDomZnak = [
    {
        path: "/",
        layout: DomZnak,
        component: DomZnakMain
    },
];

const Routes = () => {
    return <Router>
        <Switch>
            {RouteMap()}
            <Route render={() => (
                <LoaderFullsize/>
            )}/>
        </Switch>
    </Router>
};

const RouteMap = () => {
    const {context} = useContext(AppContext);

    switch (context.site.state.layout) {
        case LAYOUT_DOMZNAK:
            return SiteDomZnak.map((el, index) => {
               return componentRoute(el, index);
            });
        case LAYOUT_SADSERVICE:
            return SiteSadService.map((el, index) => {
                return componentRoute(el, index);
            });
        default:
            return [];
    }
};

const componentRoute = ({layout: Layout, component: Component, path}, index) => {
    return Component ? (<Route key={index} path={path} exact render={(props) => (
        <Layout><Component {...props} /></Layout>
    )}/>) : null;
};

export default Routes;
