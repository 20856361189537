export const Footer = () => (
    <div className="footer py-4">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-12 mb-3">
                    <div>
                        <div className="contact">
                            <a href="mailto:online@universad.ru">
                                <i className="fas fa-envelope"/>
                                online@universad.ru
                            </a>
                        </div>
                        <div className="contact">
                            <a href="tel:8 (423) 209-50-50">
                                <i className="fas fa-phone"/>
                                8 (423) 209-50-50
                            </a>
                        </div>
                        <div className="contact">
                            <a href="tel:8 (423) 209-50-50">
                                <i className="fas fa-phone"/>
                                +7 (914) 340-15-01
                            </a>
                        </div>
                        <div className="contact">
                            <a href="https://wa.me/79841995050">
                                <i className="fab fa-whatsapp"/>
                                8 (984) 199-50-50
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 mb-3">
                    <a className="btn btn-dark btn-social mx-2" target="_blank"
                       href="https://vk.com/universad1">
                        <i className="fab fa-vk"/>
                    </a>
                    <a className="btn btn-dark btn-social mx-2" target="_blank" href="https://ok.ru/universad">
                        <i className="fab fa-odnoklassniki"/>
                    </a>
                    <a className="btn btn-dark btn-social mx-2" target="_blank"
                       href="https://t.me/universadshop">
                        <i className="fab fa-telegram"/>
                    </a>
                </div>
                <div className="col-lg-12">© Универсад 2022</div>
            </div>
        </div>
    </div>
)