export const OrderReducer = (state, action) => {
    switch (action.type) {
        case 'prepareForm':
            return {...state,
                material: action.payload.material,
                address: action.payload.address,
                house: action.payload.house,
                color: action.payload.color,
                article: action.payload.article,
                open: true
            };
        case 'toggleModal':
            return {...state, open: !state.open};
    }
};

export const OrderInitialState = {
    open: false,
    material: null,
    street: null,
    house: null,
    color: null,
    article: null
};
