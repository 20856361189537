import {Base} from "./base.api";
import {API_HOST} from "../const";

const BASE_URL = `${API_HOST}/landing/domznak`;

export class Domznak extends Base {
    feedback(data) {
        return this.fetchJSON(`${BASE_URL}/feedback`, {
            method: 'post',
            body: JSON.stringify(data)
        }).then(response => {
            return response.data
        })
    }
}