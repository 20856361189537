import {useEffect} from 'react'

import Routes from './routing/routes'
import {AppContext, AppContextValue} from "./store/context";
import {DOMAIN_DOMZNAK, DOMAIN_SADSERVICE, LAYOUT_DOMZNAK, LAYOUT_SADSERVICE} from "./const";

export default function App() {
    const context = AppContextValue();
    const punycode = require('punycode');

    useEffect(() => {
        const domain = punycode.toUnicode(window.location.hostname);
        switch (domain) {
            case DOMAIN_DOMZNAK:
                context.site.dispatch({type: "initialize", payload: {layout: LAYOUT_DOMZNAK}});
                break;
            case DOMAIN_SADSERVICE:
                context.site.dispatch({type: "initialize", payload: {layout: LAYOUT_SADSERVICE}});
                break;
            default:
                window.location.replace('https://universad.ru')
        }
    }, []);

    return <AppContext.Provider value={{context}}>
        <Routes/>
    </AppContext.Provider>
}

