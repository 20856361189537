import React from 'react'

export class Main extends React.Component {

    constructor() {
        super()
    };

    render() {
        return <div>1</div>
    }
}