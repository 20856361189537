export const SiteReducer = (state, action) => {
    switch (action.type) {
        case 'initialize':
            return {...state, layout: action.payload.layout, loaded: true};
    }
};

export const SiteInitialState = {
    loaded: false,
    layout: null,
}

