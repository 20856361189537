import React from "react";
import {Base} from "./Base";
import {OrderForm} from "../components/domznak/Form"

import './../static/css/domznak.css';

export const DomZnak = ({children}) => {
    return <Base>
        {children}
        <OrderForm/>
    </Base>
};
