import React, {createContext, useEffect, useReducer, useState} from "react";
import {Processor} from "../api/processor";
import {toast} from 'react-toastify'
import {SiteInitialState, SiteReducer} from "./reducers";
import {OrderInitialState, OrderReducer} from "./domznak/reducers";


export const AppContext = createContext();
export const AppContextValue = () => {
    const isMobile = useCheckMobileScreen();
    const [siteState, siteDispatch] = useReducer(SiteReducer, SiteInitialState);
    const [domznakOrderState, domznakOrderDispatch] = useReducer(OrderReducer, OrderInitialState);

    return {
        site: {
            state: siteState,
            dispatch: siteDispatch
        },
        settings: {
            isMobile: isMobile
        },
        domznak: {
            order: {
                state: domznakOrderState,
                dispatch: domznakOrderDispatch
            }
        },
        toast: toast,
        processor: Processor
    }
};

const useCheckMobileScreen = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    return (width <= 640);
};
